.searchFormBox {
    position: relative;

    input {
        width: 100%;
        height: 40px;
        padding: 5px 35px 5px 10px;
        font-size: 14px;
        border: 1px solid #9a9898;
        @extend .transition;

        &:focus {
            border: 1px solid $brand-primary;
        }
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        color: #9a9898;
        @extend .transition;

        i {
            position: relative;
            top: -2px;
        }

        &:hover {
            color: darken(#9a9898, 15%);
        }
    }
}


.widget {
    margin-bottom: 30px;
    padding: 15px;
    background: #f3f3f3;

    select {
        width: 100%;
        padding: 6px 12px;
        height: 40px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            a {
                display: block;
                padding: 7px 0;
                //font-size: 14px;
                line-height: 1.3;
                border-bottom: 1px solid #d8d8d8;
            }

            &:first-child a {
                border-top: 1px solid #d8d8d8;
            }
        }
    }

    &.widget_categories {
        ul li {
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 5px;
            a {
                display: inline-block;
                padding: 5px 8px;
                font-size: 14px;
                line-height: 1.1;
                border: 1px solid $brand-primary;
                background-color: $brand-primary;
                color: #fff;

                &:hover {
                    background-color: #fff;
                    color: $brand-primary;
                }
            }
        }
    }


    .calendar_wrap {
        caption {
            padding-top: 0;
        }
        table {
            width: 100%;
            tr {
                td, th {
                    text-align: center;
                    vertical-align: middle;
                    border: 1px solid #cccccc;
                }
            }
        }

        #today {
            border: 2px solid $brand-primary;
            color: $brand-primary;
            font-weight: bold;
        }

        #prev {
            text-align: left;
        }

        #next {
            text-align: right;
        }
    }
}

.widget-title {
    font-size: 20px;
    text-transform: uppercase;
}
