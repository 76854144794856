//pagination
.wp-pagenavi {
    padding: 15px 0;
}

.wp-pagenavi a, .wp-pagenavi span {
    padding: 5px 10px;
    color:#8c8888;
    display: inline-block;
}

.wp-pagenavi a:hover, .wp-pagenavi span.current {
    border-color: $brand-primary;
    background-color: $brand-primary;
    color: #eaeaea;
}

.btn-readmore {
    display: inline-block;
    padding: 8px 12px;
    border: 1px solid $brand-primary;
    font-size: 14px;
    line-height: 1;

    &:hover {
        background-color: $brand-primary;
        color: #fff;

    }
}


//accordion
.panel-group .panel {
    margin-bottom: 10px;
}
.panel-heading {
    padding: 0;

    a {
        display: block;
        padding: 10px 15px;
    }
}

.accordion-toggle {
    i {
        @extend .transition;
    }
}

.accordion-toggle[aria-expanded="false"] {
    i {
        transform: rotate(0deg);
    }
}

.accordion-toggle[aria-expanded="true"] {
    i {
        transform: rotate(-180deg);
    }
}
