.entry-post-box {
    padding: 15px;
    border: 1px solid #e3e3e3;
    margin-bottom: 20px;

    .entry-title {
        font-size: 22px;
        line-height: 1.2;
        margin-bottom: 10px;

        // a {
        //     color: $text-color;
        // }
        //
        // &:hover {
        //     a {
        //         color: $brand-primary;
        //     }
        // }
    }

    p {
        margin-bottom: 10px;
    }
}

.entry-meta {
    margin-bottom: 10px;
    padding: 7px 10px;
    font-size: 13px;
    background-color: #f3f3f3;

    i  {
        margin-right: 5px;
        color: #b7b5b5;
    }
}

.entry-date {
    margin-right: 15px;
}

.entry-category {
    a {
        display: inline-block;
        padding: 3px 6px;
        margin-right: 5px;
        font-size: 12px;
        line-height: 1;
        //border: 1px solid #cacaca;
        background-color: #b7b5b5;
        color: #fff;

        &:hover {
            background-color: darken(#b7b5b5, 10%);
        }
    }
}

.single-content {
    img {
        max-width: 100%;
        height: auto;
    }
}

.not-found {
    .searchFormBox {
        max-width: 400px;
        width: 100%;
    }
}

.page-search-form {
    .searchform {
        padding: 15px;
        margin-bottom: 30px;
        background: #f3f3f3;
    }
}
