.entry-testimonial {
    margin-bottom: 30px;
}

.entry-testimonial-content {
    position: relative;
    padding: 15px;
    background-color: #f1f1f1;
    border-radius: 6px;
    margin-bottom: 20px;

    &:after {
        content: "";
        position: absolute;
        left: 25px;
        bottom: -14px;
        width: 0;
        height: 0;
        border-top: 14px solid #f1f1f1;
        border-left: 4px solid transparent;
        border-right: 28px solid transparent;
    }

    &:before {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        right: 10px;
        left: auto;
        width: 50%;
        top: 75%;
        max-width:300px;
        background: #f1f1f1;
        -webkit-box-shadow: 0 15px 10px #777;
        -moz-box-shadow: 0 15px 10px #777;
        box-shadow: 0 15px 10px #777;
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
        transform: rotate(3deg);
    }

    p {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.entry-testimonial-author {
    display: inline-block;
    padding: 5px 8px;
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 16px;
    color: #6d6d6d;
    font-style: italic;
    border: 1px solid #adaaaa;
    border-radius: 4px;
}

.guide-box {
    padding: 15px;
    border: 1px solid #c5c5c5;
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
    background-color: #f1f1f1;

    p {
        margin-bottom: 0;
    }
}
