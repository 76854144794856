/* slick  Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

}
.slick-dots li
{
    width: 15px;
    height: 15px;
}
.slick-dots li button
{
    width: 15px;
    height: 15px;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    width: 14px;
    height: 14px;

    content: '';

    opacity: 1;
    background-color: #ccc;
    border: 1px solid #4d4d4d;;

}
.slick-dots li.slick-active button:before
{
    opacity: 1;
    background-color: $brand-primary;
}


.products-slider {
    .slick-prev:before,
    .slick-next:before {
        color: black;
    }
    .slick-prev {
        background: url(../images/left-arrow.png) no-repeat;
        font-size: 0;
        content: "";
        height: 40px;
        width: 24px;
        z-index: 2;
        left: -50px;
    }
    .slick-next {
        background: url(../images/right-arrow.png) no-repeat;
        font-size: 0;
        content: "";
        height: 40px;
        width: 24px;
        z-index: 2;
        right: -50px;
    }
    .slick-prev:before, .slick-next:before {
        font-family: inherit;
        font-size: 0;
    }
}