/*======================================
    | FAQ PAGE STYLE |
========================================*/
.faq-wrapper {

}

.faq-item {
    border: 1px solid $brand-primary;
    margin-bottom: 15px;
    // &:nth-of-type(odd) {
    //     .faq-content {
    //         background-color: #f9f9f9;
    //     }
    // }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.faq-title {
    margin-bottom: 0;
    font-size: 20px;
    padding: 10px 15px;
    color: #fff;
    background-color: $brand-primary;
}

.faq-content {
    padding: 15px;

    p:last-of-type {
        margin-bottom: 0;
    }
}
