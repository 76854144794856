html, html a, body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}

html, body {
    -ms-overflow-style: scrollbar;
    overflow-x: hidden;
}

.transition {
    transition: all .4s ease-in-out;
}

.padtb {
    padding-top: 80px;
    padding-bottom: 80px;
}
.padt {
    padding-top: 80px;
}


.hyperlink {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}

a {
    @extend .transition;

    &:focus {
        outline: 0;
        outline: none;
    }
}

p, ul, ol {
    margin-bottom: 25px;
}

ul ul,
ol ol {
    margin-bottom: 0;
} 

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 0;
    margin-bottom: 20px;
}

h4, .h4, h5, .h5, h6, .h6 {
    margin-top: 0;
    margin-bottom: 15px;
}

.separator {
    height: 10px;
    background-color: #eeeeee;
}

.border-top {
    border-top: 10px solid #eeeeee;
}

.border-bottom {
    border-bottom: 10px solid #eeeeee;
}

.shadow-top {
    box-shadow: inset 0 5px 5px -2px rgba(0,0,0,.3);
}


.bottom-box-shadow {
    box-shadow: 0 8px 6px -6px rgba(0,0,0,0.6);
}

.down-gray-bar {
    -webkit-box-shadow: inset 0px 5px 5px 0px rgba(215, 215, 215, 1);
    -moz-box-shadow: inset 0px 5px 5px 0px rgba(215, 215, 215, 1);
    box-shadow: inset 0px 5px 5px 0px rgba(215, 215, 215, 1);
    min-height: 30px;
    width: 100%;
}

.up-gray-bar {
    -webkit-box-shadow: inset 0px -5px 5px 0px rgba(215, 215, 215, 1);
    -moz-box-shadow: inset 0px -5px 5px 0px rgba(215, 215, 215, 1);
    box-shadow: inset 0px -5px 5px 0px rgba(215, 215, 215, 1);
    min-height: 30px;
    width: 100%;
}

.section-title {
    margin-bottom: 40px;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #067b4e;
}

.sub-title {
    margin-bottom: 30px;
    font-size: 18px;
    font-style: italic;
    color: $brand-primary;
}

.white {
    color: #ffffff;
}


.page-hero {
    height: 250px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.page-title {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    color: $brand-primary;
    border-bottom: 1px solid $brand-primary;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 80px;
        background-color: $brand-primary;
    }

    span {
        font-size: 24px;
        font-weight: normal;
        color: #545454;
    }
}

.wow {
    visibility: hidden;
}
