/*======================================
    | SECTION STYLE |
========================================*/
.qlink-area {
    position: relative;    
}


.qlink-item {
    position: relative;
    padding: 0 15px;
    outline: 0;
   // color: #fff;

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: normal;
        color: #067b4e;
        @extend .transition;
        &:hover {
            color: $brand-primary;
        }
    }

    p {
        // max-width: 250px;
    }

    &:hover {
        .qlink-thum {
            box-shadow: 2px 7px  3px -2px rgba(0, 0, 0, 0.5);
        }

        span {
            // background-color: #f9bd1c;

            &:after {
                opacity: 0;
                transform: scale(1.3);
            }
        }

        h2 {
            // color: $brand-primary;
        }
    }
}

.quick-thum-content {
    padding: 0 25px;
}

.quick-thum-wrap {
    position: relative;
    width: 250px;
    margin: 0 auto 30px;
    a {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        color:#fff;
        line-height: 70px;
        box-shadow: 0 0 0 4px #fbcd50;

        background: rgb(251,204,32);
        background: -moz-linear-gradient(top,  rgba(251,204,32,1) 0%, rgba(251,204,23,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(251,204,32,1) 0%,rgba(251,204,23,1) 100%);
        background: linear-gradient(to bottom,  rgba(251,204,32,1) 0%,rgba(251,204,23,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbcc20', endColorstr='#fbcc17',GradientType=0 );

        span {
            position: relative;
            z-index: 5;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
            background: rgb(251,204,32);
            background: -moz-linear-gradient(top,  rgba(251,204,32,1) 0%, rgba(251,204,23,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(251,204,32,1) 0%,rgba(251,204,23,1) 100%);
            background: linear-gradient(to bottom,  rgba(251,204,32,1) 0%,rgba(251,204,23,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbcc20', endColorstr='#fbcc17',GradientType=0 );
            @extend .transition;
        }

        &:hover {
            box-shadow: 0 0 0 8px rgba($brand-primary, 0.4);
            background: rgb(251,204,23);
            background: -moz-linear-gradient(top,  rgba(251,204,23,1) 0%, rgba(251,204,32,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(251,204,23,1) 0%,rgba(251,204,32,1) 100%);
            background: linear-gradient(to bottom,  rgba(251,204,23,1) 0%,rgba(251,204,32,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbcc17', endColorstr='#fbcc20',GradientType=0 );

            &:after {
                transform: scale(1.5);
                opacity: 0;
            }
        }
    }
}

.qlink-thum {
    width: 250px;
    height: 250px;
    background: #ededed;
    border-radius: 50%;
    border: 10px solid #fff;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 2px 7px  3px -2px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;

    img {
        //max-width: 100%;
        height: auto;
        max-height: 170px;
        margin: 0 auto;
    }
}

.products-slider {
    margin-top: 25px;
}


/*======================================
    | FEATURE SECTION STYLE |
========================================*/
.feature-area {
    background-color: #1c1c1c;
    color: #fff;
    position: relative;
}

.feature-item {
    position: relative;
    background: radial-gradient(circle, #06764a, #02552e);

    .item-bg {
        position: absolute;
        left: 0;
        width: 50%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .pest-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 120px;
        height: 120px;
        margin-top: -60px;
        margin-left: -60px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .feature-item-content {
        padding: 100px 0 100px 90px;
        width: 50%;
        color: #fff;
        float: right;

        p:last-of-type {
            margin-bottom: 0;
            text-align: justify;
        }
    }

    h2 {
        margin-bottom: 30px;
        font-size: 28px;
        font-weight: 900;
        text-transform: uppercase;

        a {
            color: #ffffff;
            &:hover,&:focus {
                color: $brand-primary;
            }
        }

        span {
            font-weight: 300;
            //color: $brand-primary;
            display: block;
        }
    }

    &:nth-of-type(even) {
        .feature-item-content {
            float: left;
            padding-right: 90px;
            padding-left: 0;
        }

        .item-bg {
            left: 50%;
        }
    }
}


/*======================================
    | CONTACT INFO SECTION STYLE |
========================================*/
.contact-wrapper {
    background: radial-gradient(circle, #06764a, #02552e);
    address {
        text-align: center;
        margin-bottom: 15px;
        p {
            margin-bottom: 5px;
        }
    }

    dl {
        margin-bottom: 0;
        overflow: hidden;

        dt {
            width: 50px;
        }

        dt,dd {
            display: inline-block;
        }
    }
    .social-links li a {
        i {
            color: #04643b;
        }
        &:hover {
            i {
                color: #04643b;
            }
        }
    }
}

.contact-content {
    p {
        margin-bottom: 15px;
    }
}

.inner-wrapper {
    address {
        text-align: center;
        p {
            margin-bottom: 5px;
        }
    }

    dl {
        margin-bottom: 0;
        overflow: hidden;

        dt {
            width: 50px;
        }

        dt,dd {
            display: inline-block;
        }
    }
    .social-links li a {
        i {
            color: #04643b;
        }
        &:hover {
            i {
                color: #04643b;
            }
        }
    }
}

.submit-btn-wrapper {
    text-align: center;
}


.social-links {
    // padding-top: 20px;
    li {
        a {
            overflow: hidden;
            display: block;
            width: 30px;
            height: 30px;
            color: #fff;
            border-radius: 50%;
            text-align: center;
           // font-size: 16px;
            line-height: 30px;

            background: $brand-primary;
            i {
                color: #fff;
            }

            &:hover {
               background: $brand-primary;
                 box-shadow: 0 0 0 6px rgba($brand-primary, 0.4);

                i {
                    animation: toTopFromBottom 0.3s forwards;
                    color: #fff;
                }
            }
        }
    }
}

@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}


/*======================================
    | SOCIAL FEED SECTION STYLE |
========================================*/

.feed-card {
    position: relative;
    min-height: 180px;
    padding: 20px;
    border: 3px solid #fff;

    box-shadow: 4px 4px 4px -2px rgba(0, 0, 0, 0.3);

    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #f6f6f6 100%);
    background: -webkit-linear-gradient(top,  #ffffff 0%,#f6f6f6 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#f6f6f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 );
}

.fb-feed-item,
.twitter-feed-item  {
    font-size: 14px;
    font-style: italic;

    &:focus {
        outline: 0;
        border: 0;
    }
}

.feed-title {
    margin-bottom: 15px;

    a {
        display: inline-block;
        margin-right: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        font-size: 18px;
        color: #fff;
        background-color: #002345;

        &:hover {
            background-color: $brand-primary;

            i {
                animation: toTopFromBottom 0.3s forwards;
            }
        }
    }

    span {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #002345;
    }
}

.page-link {
    position: absolute;
    top: -3px;
    right: -3px;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 18px;

    i {
        position: relative;
        z-index: 10;
        margin-left: 20px;
        margin-top: 5px;
        color: #fff;
    }

    &:hover {
        i {
            color: $brand-primary;
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
    	height: 0;
    	border-top: 50px solid #1e7bb4;
    	border-left: 50px solid transparent;
    }
}

ul.sister-com-list{
    text-align: center;
}
ul.sister-com-list li{
    list-style: none;
    display: inline-block;
    padding:5px;
    
}
ul.sister-com-list li img{
    height: 65px;
}
.com-title{
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
}
ul.sister-com-list{
    padding-left: 0;
}
.com-title{
    color: #067b4e;
}
.com-title{
    color: #067b4e;
    transition: all 0.4s ease-in-out 0s;
}
.com-title:hover {
    color: #fbcc17;
    transition: all 0.4s ease-in-out 0s;
}
.sister-com-list li:hover .com-title{
    color: #fbcc17;
}