input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {

	&:focus {
		outline: 0;
	}
}

button {
	border: 0;
	outline: 0;
	background: transparent;
}

.insta-contact-form {
	font-size: 14px;
	line-height: 1.4;
}

.form-control {
	background: #ffffff;
	background: -moz-linear-gradient(top,  #ffffff 0%, #f3f3f3 100%);
	background: -webkit-linear-gradient(top,  #ffffff 0%,#f3f3f3 100%);
	background: linear-gradient(to bottom,  #ffffff 0%,#f3f3f3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f3f3',GradientType=0 );
}

.btn-submit {
	outline: 0;
	height: 40px;
	padding: 8px 20px;
	font-size: 14px;
	line-height: 1;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 0;
	color: #04643b;
	@extend .transition;
	border: 2px solid #fbce16;

	background: #f9bf1b;

	&:hover {
		background: #fbce16;
		border: 2px solid #f9bf1b;
	}
}

textarea.form-control {
	height: 120px;
}
div.wpcf7-validation-errors {
    border: 2px solid #f00;
    color:  #f00;
}
div.wpcf7-response-output {
    margin: 15px 0;
    padding: 15px;
}
.captcha-group {
	position: relative;
	padding-left: 100px;
}
.captcha-group .captcha-img {
    position: absolute;
    left: 0;
    top: 8px;
}
.required {
	color: #f00;
}
div.wpcf7-response-output {
	margin: 0;
	padding: 10px;
}
