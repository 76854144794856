html, body {
    position: relative;
    overflow-x: hidden;
}

.top-info-content {
    position: relative;
    z-index: 6;
    padding: 8px 0 8px 15px;
    width: 100%;
    max-width: 680px;
    background-color: #fff;
    line-height: 1.2;

    a {
        display: block;
        color: #404040;
        font-size: 14px;
        line-height: 2.4;

        i {
            color: $brand-primary;
            @extend .transition;
        }

        &:hover {
            color: $brand-primary;
            i {
                color: darken($brand-primary, 10%);
            }
        }
    }

    ul {
        margin-bottom: 0;
    }

    &:before {
        position: absolute;
        content: "";
        left: -49px;
        top: 0;
        width: 0;
        height: 0;
        border-top: 49px solid #fff;
        border-left: 49px solid transparent;
    }
}

.top-contact {
    li {
        margin-right: 10px;
        vertical-align: middle;
    }
    a {
        i {
            margin-right: 10px;
            font-size: 18px;
        }
    }
}

.top-right-contact {
    text-align: right;

    a {
        color: $text-color;

        &:first-child {
            margin-right: 20px;
        }

        i {
            margin-right: 10px;
            font-size: 18px;
            vertical-align: middle;
        }

        &:hover {
            color: $brand-primary;
        }
    }
}

.top-info {
    position: relative;
    background-color: #fff;

    &:after {
        position: absolute;
        content: "";
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        background-color: #fff;
    }
}
//end header top

.logo {
    margin: -35px 0 0;
    position: relative;
    z-index: 10;

    a {
        display: block;
    }
    img {
        max-width: 320px;
    }
}

.navbar {
    border: 0;
}

.nav > li > a {
    padding-left: 16px;
    padding-right: 16px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    //line-height: 1.3;
}

.manu-bar {
    position: relative;
    &:after {
        position: absolute;
        content: "";
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        background-color: #02552e;
    }
    &:before {
        position: absolute;
        top: 25px;
        left: -135px;
        width: 50%;
        height: 0px;
        content: '';
        border-top: 80px solid #ffffff;
        border-right: 80px solid transparent;
        z-index: 9;
    }
    .navbar-nav {
        position: relative;
        z-index: 6;
        width: 100%;
        max-width: 680px;
        background-color: #02552e;
        padding-left: 45px;
        &:before {
            position: absolute;
            content: "";
            left: -80px;
            top: 0;
            width: 0;
            height: 0;
            border-bottom: 80px solid #02552e;
            border-left: 80px solid transparent;
        }
    }
}

@media (min-width: $screen-sm-min) {
        
    .navbar-nav {
        > li {
            position: relative;
            > ul {
                left: 0;
            }
        }

    }


    .navbar-nav > li:hover > .sub-menu{
        visibility: visible; /* shows sub-menu */
        opacity: 1;
        top: 100%;
        z-index: 100;
        //transform: translate(0,0);
        transition-delay: 0s, 0s, 0.3s;
    }

    .navbar-nav .sub-menu li:hover > .sub-menu {
        visibility: visible; /* shows sub-menu */
        opacity: 1;
        z-index: 100;
        transform: translate(0,0);
        transition-delay: 0s, 0s, 0.3s;
    }

    .sub-menu {
        .current-menu-parent > a,
        .current-menu-item > a {
            background: darken($brand-primary, 12%);
        }
    }
}



//slider
// .main-slider-wrapper {
//     overflow: hidden;
//     background: #ffffff;
//     min-height: 220px;
//     background: #fff url(../images/preloader.gif) center 70% no-repeat;
// }

.main-slider {
    overflow: hidden;
    background: #ffffff;
    //max-height: 400px;
    min-height: 220px;
    background: #fff url(../images/preloader.gif) center 70% no-repeat;
    .slide-item {
        display: none;
        // background-position: center center;
        // background-repeat: no-repeat;
        // background-size: cover;

        img {
            width: 100%;
            height: auto;
        }
        
        &:focus {
            outline: 0;
            border: 0;
        }
    }

    &.slick-initialized {
        .slide-item {
            display: block;
        }
    }
}

.main-slider.slick-initialized {
    background: none;
}

.bottom-box {
    background: radial-gradient(circle, #06764a, #02552e);
    height: 30px;
    width: 100%;
}


/* static menu */
.manu-bar {
    position: absolute;
    width: 100%;
    height: auto;
    background: #ffffff;
    transition: all .4s ease-in-out;
}

.navbar-fixed {
    transition: all .4s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    &:before {
        top: 0;
    }
    .logo {
        margin: 0;
        padding-top: 5px;
        img {
            max-width: 200px;
        }
    }
}