.inner-banner-wrapper {
	position: relative;
	max-height: 450px;
	overflow: hidden;
	img {
		width: 100%;
		height: auto;
	}
}


.services-item-wrapper {
	border-top: 1px dashed $brand-primary;
    border-bottom: 1px dashed $brand-primary;
    background-color: #f5f5f5;
    padding: 10px 0;
    margin-bottom: 20px;
    margin: 0;
    h3 {
    	font-size: 20px;
    	text-transform: uppercase;
    }
    img {
	    padding: 4px;
	    line-height: 1.5;
	    background-color: #fff;
	    border: 1px solid #ddd;
    }
}


.p-list-wpr {
	margin: 0;
	padding: 0;
	li {
		float: left;
		display: block;
		width: 100%;
		a {
			color: #404040;
			@extend .transition;
			&:hover, &:focus {
				color: $brand-primary;
			}
		}
	}
}

.ekko-lightbox {
	.modal-dialog {
	    margin: 150px auto 30px;
	}
}

.img-fluid-thum {
    max-height: 220px;
    overflow: hidden;
    margin-bottom: 20px;
    text-align: center;
    img {
    	min-height: 220px;
    	margin: 0 auto;
    }
}

.category-title {
	font-size: 20px;
	color: #ffffff;
	background: #02552e;
	padding: 10px 15px;
	margin-bottom: 20px;
	margin-top: 0;
	display: inline-block;
}

.profile-image {
	max-width: 100%;
	height: auto;
	margin: 0 auto;
	padding: 5px;
	background: #ffffff;
	border: 1px dashed #e03731;
	display: inline-block;
}