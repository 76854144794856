.footer {
    padding: 20px 0;
    background-color: #ffffff;

    p {
        //padding-top: 7px;
        margin-bottom: 0;
        font-size: 14px;
        color: #aaaaaa;
    }

    .social-links {
        height: auto;
        margin-bottom: 0;
        float: none;
        text-align: center;
        background-color: transparent;
        padding: 0;

        li {
            a {
                i {
                    color: #003D5D;
                }
                &:hover {
                    background-color: #3EA6DD;
                }
            }
        }
    }
}

.powerd-by {
    a {
        color: #aaaaaa;
        &:hover {
            color: $brand-primary;
        }
    }
}

.site-info {
    text-align: right;
}
