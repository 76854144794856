/*==========  Non-Mobile First Method  ==========*/

// $screen-xs:                  480px !default;
// //** Deprecated `$screen-xs-min` as of v3.2.0
// $screen-xs-min:              $screen-xs !default;
// //** Deprecated `$screen-phone` as of v3.0.1
// $screen-phone:               $screen-xs-min !default;

// // Small screen / tablet
// //** Deprecated `$screen-sm` as of v3.0.1
// $screen-sm:                  768px !default;
// $screen-sm-min:              $screen-sm !default;
// //** Deprecated `$screen-tablet` as of v3.0.1
// $screen-tablet:              $screen-sm-min !default;

// // Medium screen / desktop
// //** Deprecated `$screen-md` as of v3.0.1
// $screen-md:                  992px !default;
// $screen-md-min:              $screen-md !default;
// //** Deprecated `$screen-desktop` as of v3.0.1
// $screen-desktop:             $screen-md-min !default;

// // Large screen / wide desktop
// //** Deprecated `$screen-lg` as of v3.0.1
// $screen-lg:                  1200px !default;
// $screen-lg-min:              $screen-lg !default;
// //** Deprecated `$screen-lg-desktop` as of v3.0.1
// $screen-lg-desktop:          $screen-lg-min !default;

// // So media queries don't overlap when required, provide a maximum
// $screen-xs-max:              ($screen-sm-min - 1) !default;
// $screen-sm-max:              ($screen-md-min - 1) !default;
// $screen-md-max:              ($screen-lg-min - 1) !default;

// @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    
// }

/* Large Devices, Wide Screens */
@media only screen and (max-width : $screen-md-max) {
    .quick-thum-content {
        padding: 0;
    }
    .products-slider {
        .slick-prev {
            left: 0px;
        }
        .slick-next {
            right: 0px;
        }
    }
    .manu-bar .navbar-nav {
        max-width: 580px;
    }
    .nav > li > a {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .manu-bar .navbar-nav:before {
        border-bottom: 64px solid #02552e;
        border-left: 64px solid transparent;
        left: -79px;
    }
    .manu-bar .navbar-nav {
        max-width: 50%;
        padding-left: 0;
    }

    .manu-bar {
        &:before {
            top: 5px;
            left: -5px;
        }
        &:after {
            height: 64px;
        }
    }
    .navbar {
        min-height: 64px;
    }
    .navbar-fixed .logo img {
        max-width: 160px;
    }

    .navbar-fixed {
        &:before {
            border-top: 0;
        }
    }
    .nav > li > a {
        padding-left: 11px;
        padding-right: 11px;
        font-size: 10px;
    }
    .contact-wrapper {
        dl {
            font-size: 13px;
            dt {
                width: 45px;
            }
        }
    }
} //1199px

/* Medium Devices, Desktops */
@media only screen and (max-width : $screen-sm-max) {
    .container {
        width: 100%;
    }
    .nav > li > a {
        font-size: 9px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .manu-bar .navbar-nav {
        padding-left: 0;
    }
    .logo img {
        max-width: 270px;
    }

    .partners-photo {
        .profile-image {
            display: block;
            margin: 0 auto 30px;
        }
    }
} //991px



@media only screen and (max-width : 768px) {

} //max 768

/* Small Devices, Tablets */
@media only screen and (max-width : $screen-xs-max) {
	.padtb {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    
    .header-top {
        text-align: center;
    }

    .top-right-contact {
        margin-top: 10px;
        text-align: center;
    }

    .footer p {
        text-align: center;
    }

    .site-info {
        padding-bottom: 15px;
    }

    .page-hero {
        height: 180px;
    }

    .gallery-columns-6 .gallery-item {
        width: 33.33333% !important;
        max-width: 33.33333%;
    }

    .widget-area {
        margin-top: 30px;
    }

    .page-title {
        font-size: 24px;
    }

    .feature-item {
        .item-bg {
            position: static;
            width: 100%;
            height: 320px;
            display: block;
        }

        .pest-icon {
            top: 250px;
        }

        .feature-item-content {
            padding: 50px 0;
            width: 100%;
            float: inherit;
        }

        &:nth-of-type(even) {
            .feature-item-content {
                padding: 50px 0;
                width: 100%;
                float: inherit;
            }
        }
    }
    .logo {
        margin: 3px 0 0 15px;
        display: inline-block;
        img {
            max-width: 160px;
        }
    }

    .navbar-fixed .logo {
        margin: 3px 0 0 15px;
    }

    .navbar-fixed .logo img {
        max-width: 130px;
    }
    .manu-bar {
        &:before {
            position: static;
            border-top: 0;
            border-right: 0;
        }
        &:after {
            position: static;
        }
    }
    .navbar {
        min-height: 65px;
    }
    .manu-bar {
       z-index: 9999; 
    }
    .manu-bar .navbar-nav {
        padding: 0;
        margin: 0;
        max-width: 100%;
        
    }
    .top-info-content {
        padding: 5px 0;
    }
    .top-info {
        border-bottom: 1px solid #02552e;
    }
    .top-info-content:before {
        display: none;
    }
    .top-info-content ul {
        margin-bottom: 0;
        float: inherit !important;
        width: 100%;
        text-align: center;
    }
    .social-top {
        margin-top: 10px;
        display: none;
    }
    .navbar-toggle {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .contact-wrapper address, .contact-wrapper dl, .inner-wrapper address, .inner-wrapper dl {
        text-align: center;
    }
    .services-item-wrapper {
        margin-bottom: 15px;
        h3 {
            margin-top: 20px;
            text-align: center;
        }
        img {
            margin: 0 auto;
        }
    }

    .nav > li > a {
        font-size: 13px;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }

    .img-fluid-thum {
        padding: 5px;
    }

    .main-slider {
        min-height: 100px;
    }

    .pe-wrapper {
        text-align: center;
        margin-bottom: 20px;
    }

    .partners-photo {
        margin-bottom: 30px;
    }
    

} //767px


/* Extra Small Devices, Phones */ 
@media only screen and (max-width : $screen-phone) {

	.xtn-100 {
        width: 100%;
    }

    .quick-thum-wrap {
        width: 220px;
    }

    .qlink-thum {
        width: 220px;
        height: 220px;
    }
    .profile-image {
        max-width: 100%;
    }


} // 480px

@media only screen and (max-width : 479px) {
	


} // 479px


/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
    

} //320px